import { template as template_cfbdea095d744d1486e95a28c0cb1917 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_cfbdea095d744d1486e95a28c0cb1917(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
