import { template as template_44def038507441938eb7b3f72878dc32 } from "@ember/template-compiler";
const FKControlMenuContainer = template_44def038507441938eb7b3f72878dc32(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
