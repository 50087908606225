import { template as template_083c8ca6e0964135a72fa887fa26a0b3 } from "@ember/template-compiler";
const FKText = template_083c8ca6e0964135a72fa887fa26a0b3(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
